<template>
    <div class="all_data_box">
        <div class="search-box">
            <div class="search-one">
                <p class="left">
                    <span>公司筛选</span>
                    <el-input
                        size="small"
                        style="width:180px"
                        placeholder="请输入内容"
                        clearable
                        v-model="searchVal.name"
                    >
                    </el-input>
                    <el-button
                        @click="getTableData2"
                        type="primary"
                        size="small"
                        icon="el-icon-search"
                    ></el-button>
                    <span v-if="btnP.customer">客户列表</span>
                    <el-radio-group
                        v-if="btnP.customer"
                        v-model="searchVal.partakeAdminId"
                        @change="getTableData2"
                        size="small"
                    >
                        <el-radio-button label="全部"></el-radio-button>
                        <el-radio-button label="我参与的"></el-radio-button>
                    </el-radio-group>
                    <span>产品</span>
                    <el-radio-group
                        v-model="searchVal.productType"
                        @change="getTableData2"
                        size="small"
                    >
                        <el-radio-button label="全部"></el-radio-button>
                        <el-radio-button label="腾讯邮箱"></el-radio-button>
                        <el-radio-button label="阿里邮箱"></el-radio-button>
                    </el-radio-group>
                </p>
                <p>
                    <el-button
                        type="primary"
                        style="margin-right:16px; border-color: #2370EB;background:#2370EB"
                        size="small"
                        @click="getTableData2()"
                        >查询</el-button
                    >
                    <el-button
                        @click="searchEmpty"
                        type="primary"
                        style="margin:0;background:none;border-color:#D9D9D9;color:#333333"
                        icon="el-icon-refresh-left"
                        size="small"
                        >重置</el-button
                    >
                    <el-button
                        v-show="fold"
                        @click="fold = !fold"
                        type="text"
                        style="margin-right:29px;color:#2162CB"
                    >
                        收起 <i class="el-icon-arrow-up"></i>
                    </el-button>
                    <el-button
                        v-show="!fold"
                        @click="fold = !fold"
                        type="text"
                        style="margin-right:29px;color:#2162CB"
                    >
                        展开 <i class="el-icon-arrow-down"></i>
                    </el-button>
                </p>
            </div>
            <div class="search-two" v-show="fold">
                <span v-if="btnP.department">部门</span>
                <el-select
                    v-if="btnP.department"
                    size="small"
                    filterable
                    style="width:180px"
                    clearable
                    v-model="searchVal.departmentId"
                    placeholder="请选择"
                    @change="getTableData"
                >
                    <el-option
                        v-for="item in departmentDataList"
                        :key="item.id"
                        :label="item.departmentName"
                        :value="item.id"
                    ></el-option>
                </el-select>
                <span v-if="btnP.reportUser">成员</span>
                <el-select
                    v-if="btnP.reportUser"
                    filterable
                    v-model="searchVal.adminId"
                    size="small"
                    style="width:180px"
                    clearable
                    placeholder="请选择"
                    @change="getTableData2"
                >
                    <el-option
                        v-for="itm in departmentRoleReportList"
                        :key="itm.id"
                        :label="itm.nickName"
                        :value="itm.id"
                    >
                    </el-option>
                </el-select>
                <span>邮箱运营</span>
                <el-select
                    v-model="searchVal.operateAdminId"
                    size="small"
                    style="width:180px"
                    clearable
                    placeholder="请选择"
                    @change="getTableData2"
                >
                    <el-option
                        v-for="itm in departmentRoleList"
                        :key="itm.id"
                        :label="itm.nickName"
                        :value="itm.id"
                    >
                    </el-option>
                </el-select>
                <span>商机类型</span>
                <el-select
                    v-model="searchVal.businessType"
                    size="small"
                    style="width:180px"
                    clearable
                    @change="getTableData2"
                    placeholder="请选择"
                >
                    <el-option
                        v-for="item in $searchForm.boType()"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                    >
                    </el-option>
                </el-select>
                <div class="last_input" style="display:inline-block">
                    <span>商机进度</span>
                    <el-select
                        v-model="searchVal.businessProgress"
                        size="small"
                        style="width:180px"
                        clearable
                        placeholder="请选择"
                        @change="getTableData2"
                    >
                        <el-option
                            v-for="item in $searchForm.boSpeed()"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </div>
            </div>
        </div>
        <div class="table-title">
            客户列表

            <el-button type="primary" @click="goback">
                <i class="el-icon-arrow-left"></i>
                返回列表
            </el-button>
        </div>
        <Table
            ref="table"
            @onGiveUp="onGiveUp"
            @totalNum="totalNum"
            @onUpdate="onUpdate"
        >
        </Table>
        <div class="page-box">
            <el-pagination
                @current-change="handleCurrentChange"
                @size-change="handleSizeChange"
                :current-page.sync="currentPage"
                :page-size="pageSize"
                :page-sizes="[10, 20, 50]"
                layout="total,sizes,prev, pager, next, jumper"
                :total="total"
            ></el-pagination>
        </div>
        <!-- 放弃 -->
        <el-dialog
            :visible.sync="dialogGiveUp"
            width="560px"
            :before-close="handleCloseDialog"
        >
            <template slot="title">
                <div
                    style="display:flex; align-items: center;color:#000000;font-size:16px;font-weight: 500;"
                >
                    放弃报备
                </div>
            </template>
            <div class="content">
                是否放弃当前报备？
            </div>
            <div class="but" slot="footer">
                <el-button
                    round
                    type="primary"
                    size="mini"
                    style="background:none;border-color:#D5D6DF;color:#666666;margin-left: auto;"
                    @click="handleCloseDialog"
                    >取消</el-button
                >
                <el-button
                    round
                    type="primary"
                    style="background:#2370EB;border-color:#2370EB;color:#fff;margin-right:15px"
                    size="mini"
                    @click="onSubmitGiveUp"
                    >确定</el-button
                >
            </div>
        </el-dialog>
        <!-- 详情 -->
        <el-drawer
            :visible.sync="drawerDetail"
            :direction="direction"
            :before-close="handleCloseDrawer"
            size="900px"
        >
            <template slot="title">
                <div
                    style="display:flex; align-items: center;font-size:16px;font-weight: 500;color: rgba(0, 0, 0, 0.85);line-height: 24px;"
                >
                    报备详情
                </div>
            </template>
            <Detail @close="handleCloseDrawer" ref="detail" />
        </el-drawer>
    </div>
</template>
<script>
import { roleList } from '@/api/customer/customer.js';
import { departmentList } from '@/api/admin/framework/framework.js';
import Table from '../table/table.vue';
import Detail from '../detail/detail.vue';
export default {
    components: {
        Table,
        Detail,
    },
    data() {
        return {
            searchVal: {
                productType: '全部',
                partakeAdminId: '全部',
                adminId: '',
            },
            fold: true,
            direction: 'rtl',
            drawerDetail: false,
            dialogGiveUp: false,
            currentPage: 1,
            pagesize: 20,
            total: 0,
            btnP: {},
            departmentRoleList: [],
            departmentRoleReportList: [],
            departmentDataList: [],
        };
    },
    mounted() {
        this.getBtn();
    },
    methods: {
        getBtn() {
            this.btnP = JSON.parse(sessionStorage.getItem('btnP'));
            this.getData();
            this.roleList();
            if (this.btnP.reportUser && !this.btnP.department) {
                this.roleReportList();
            }
            if (this.btnP.department) {
                this.departmentData();
            }

            // console.log(this.btnP);
        },
        getTableData() {
            this.searchVal.adminId = '';
            this.roleReportList();

            this.getData();
        },
        getTableData2() {
            this.currentPage = 1;
            this.getData();
        },
        // 部门列表
        departmentData() {
            let data = {
                param: {},
            };
            departmentList(data).then((res) => {
                this.departmentDataList = res.data;
            });
        },
        totalNum(num) {
            this.total = num;
        },
        getData() {
            setTimeout(() => {
                this.$refs.table.getData(
                    this.searchVal,
                    this.currentPage,
                    this.pageSize,
                    this.btnP
                );
            }, 0);
        },
        // 运营人角色列表
        roleList() {
            let data = {
                param: {
                    roleTypeList: [8, 17],
                },
            };
            roleList(data).then((res) => {
                if (res.code == 200) {
                    this.departmentRoleList = res.data;
                }
            });
        },
        // 报备人角色列表
        roleReportList() {
            let data = {
                param: {
                    departmentId: sessionStorage.getItem('departmentId'),
                    roleTypeList: [4, 8],
                },
            };
            if (this.btnP.department) {
                data.param.departmentId = this.searchVal.departmentId;
            }
            roleList(data).then((res) => {
                if (res.code == 200) {
                    this.departmentRoleReportList = res.data;
                }
            });
        },
        goback() {
            this.$router.go(-1);
        },
        //     放弃
        onGiveUp(row) {
            this.dialogGiveUp = true;
        },
        onSubmitGiveUp() {
            this.dialogGiveUp = false;
        },
        //     编辑
        onUpdate(row) {
            this.drawerDetail = true;
            setTimeout(() => {
                if (this.btnP.update) {
                    this.$refs.detail.getData(row, false);
                } else {
                    this.$refs.detail.getData(row, true);
                }
            }, 0);
        },
        handleCurrentChange() {},
        handleSizeChange() {},

        handleCloseDrawer() {
            this.drawerAdd = false;
            this.drawerDetail = false;
            this.getData();
        },
        handleCloseDialog() {
            this.dialogGiveUp = false;
            this.getData();
        },
        searchEmpty() {
            this.searchVal = {
                productType: '全部',
                partakeAdminId: '全部',
                adminId: '',
            };
            this.getData();
        },
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.all_data_box {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .search-box {
        width: 100%;
        background: #fff;
        border-radius: 4px;
        margin-bottom: 12px;
        span {
            font-size: 12px;
            font-weight: 600;
            color: #333333;
            margin: 0 16px;
        }
        .search-one {
            padding: 10px 0 0 0;
            margin-bottom: 12px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            .left {
                button {
                    background: #2370eb;
                    border-radius: 2px;

                    margin-right: 16px;
                }
            }
        }
        .search-two {
            padding-bottom: 10px;
        }
    }
    .table-title {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        background: #fff;
        padding: 16px;
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        div {
            .color {
                border-color: #2370eb;
            }
            p {
                border-radius: 4px;
                border: 1px solid #e5e5e5;
                padding: 5px 8px;
                min-width: 80px;
                position: relative;
                margin-left: 12px;
                display: inline-block;
                cursor: pointer;
                i {
                    position: absolute;
                    top: 0;
                    left: -3px;
                    font-size: 10px;
                }
            }
            p:nth-child(2) {
                i {
                    color: #2370eb;
                }
            }
            p:nth-child(4) {
                i {
                    color: #ff760f;
                }
            }
            p:nth-child(5) {
                i {
                    color: #df5045;
                }
            }
        }
        .add {
            border-color: #d9d9d9;
            color: #fff;
        }
        p {
            font-size: 14px;
            font-weight: 400;
            color: #333333;
            line-height: 20px;
        }

        button {
            font-size: 12px;
            padding: 0 10px;
            height: 24px;
            background: #ffffff;
            border-radius: 2px;
            border: 1px solid #2370eb;
            cursor: pointer;
            color: #2370eb;
            margin-left: 16px;
            background: transparent;
            .el-icon-arrow-left {
                background: #2370eb;
                width: 20px;
                height: 20px;
                color: #fff;
                text-align: center;
                line-height: 20px;
                transform: scale(0.6);
                border-radius: 50%;
            }
        }
    }
    .page-box {
        background: #fff;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .Times {
        display: inline-block;
        width: 20px;
        line-height: 16px;
        font-size: 12px;
        font-weight: 500;
        color: #ffffff;
        background: #ff760f;
        border-radius: 63px 63px 63px 1px;
        text-align: center;
    }
    .Timess {
        background: #ff9385;
        margin-left: 3px;
    }
}
.content {
    height: 200px;
    padding-left: 50px;
    p {
        margin-top: 50px;
        font-size: 14px;
        color: #333;
        line-height: 30px;
    }
}
.but {
    width: 100%;
    height: 48px;
    border-top: 1px solid #e5e5e5;
    display: flex;
    align-items: center;
    justify-content: space-between;
    button {
        width: 72px;
    }
}
@media screen and(max-width:1519px) {
    .last_input {
        margin-top: 12px;
    }
}
.content {
    height: 200px;
    padding-left: 50px;
    padding-top: 25px;
    box-sizing: border-box;
    font-size: 16px;
}
/deep/.el-dialog__footer {
    padding: 0;
    text-align: right;
    height: 48px;
    line-height: 48px;
}
.el-pagination {
    padding: 7px;
}
</style>
